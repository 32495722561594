import React from "react"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const WorksListsWrapper = styled.section`
  background-color: #1c1c1c;
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #141414;
    height: 30%;
    content: "";
    z-index: -1;
  }
  .each-wl {
    margin-top: 40px;
    .ew-img {
      overflow: hidden;
      img {
        -o-transition: all 0.75s ease-out !important;
        -moz-transition: all 0.75s ease-out !important;
        -webkit-transition: all 0.75s ease-out !important;
        transition: all 0.75s ease-out !important;
      }
      &:hover {
        img {
          -o-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
    }
  }
`

const WorksLists = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "april-thumb.png" }) {
        ...worksLists
      }

      image2: file(relativePath: { eq: "chapel-thumb.png" }) {
        ...worksLists
      }

      image3: file(relativePath: { eq: "dars-thumb.png" }) {
        ...worksLists
      }

      image4: file(relativePath: { eq: "sterling-thumb.png" }) {
        ...worksLists
      }

      image5: file(relativePath: { eq: "saymour-thumb.png" }) {
        ...worksLists
      }

      image6: file(relativePath: { eq: "kaamcha-thumb.png" }) {
        ...worksLists
      }

      image7: file(relativePath: { eq: "interior-of-shaw-thumb.png" }) {
        ...worksLists
      }

      image8: file(relativePath: { eq: "hartmann-thumb.png" }) {
        ...worksLists
      }

      image9: file(relativePath: { eq: "sam-thumb.jpg" }) {
        ...worksLists
      }
    }
  `)

  return (
    <WorksListsWrapper className="section">
      <Container>
        <div className="section-info text-center">
          <div className="si-title">
            <h2>My Portfolio</h2>
            <span>Recent Works</span>
          </div>
          <Row className="justify-content-center">
            <Col xs={8}>
              <div className="si-desc">
                <p>
                  Below are the series of my works which I was a part of. All
                  the projects listed below are accomplished while I was working
                  with different Organizations or doing freelancing in different
                  times. I am nothing but proud to be part of those
                  organizations and these projects.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image5.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image6.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image7.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image8.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="each-wl">
              <div className="ew-img">
                <Img
                  fluid={data.image9.childImageSharp.fluid}
                  alt="Frontend Development"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </WorksListsWrapper>
  )
}

export default WorksLists

export const query = graphql`
  fragment worksLists on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
`
