import React from "react"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import WorksLists from "../components/WorksLists"
import Form from "../components/Form"
import Footer from "../components/Footer"

const BannerContent = {
  title: "My Works",
  description:
    "So far I have developed over 100+ websites/applications, while working for agencies or doing freelancing in different platform in different times.",
}

const Works = () => {
 return (
   <Layout>
     <Banner
       title={BannerContent.title}
       description={BannerContent.description}
     ></Banner>
     <WorksLists></WorksLists>
     <Form></Form>
     <Footer></Footer>
   </Layout>
 )
}

export default Works